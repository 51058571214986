export function globalShardFirst<T = any>(data: T[] = []): T[] {
  const ret: T[] = []
  for (let i = 0; i < data.length; i += 1) {
    const item: any = data[i]
    if (item.ShardIndex === 65535) {
      ret.unshift(item)
    } else {
      ret.push(item)
    }
  }
  return ret
}
