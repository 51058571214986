import { useContext } from 'react'
import { LanguageContext } from '@/hooks/LanguageContext'
import { gettext } from '@/utils/langsBook'

// fix Text content did not match，server not match client
export default function useLanguage() {
  const { books, locale } = useContext(LanguageContext)
  return {
    translate: (key: string, ...arg: any) => gettext(key, arg, books),
    locale,
  }
}
